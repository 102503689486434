import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { useModal } from "../../hooks";
import { Fragment, useState } from "react";

export const SellerCompanyRadio = (props) => {
  const { status, onStatusChange } = props;
  const theme = useTheme();
  const [isOpenConfirmModal, openConfirmModal, closeConfirmModal] = useModal();
  const [toStatus, setToStatus] = useState("");

  const handleConfirm = () => {
    closeConfirmModal();
    onStatusChange(toStatus);
  };

  return (
    <Fragment>
      <FormControl>
        <RadioGroup
          row
          name="row-radio-buttons-group"
          value={status}
          onChange={(e) => {
            openConfirmModal();
            setToStatus(e.target.value);
          }}
        >
          <FormControlLabel
            value="active"
            control={
              <Radio
                sx={{
                  color: theme.palette.theme.main,
                  "&.Mui-checked": {
                    color: theme.palette.theme.main,
                  },
                }}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                Active
              </span>
            }
          />
          <FormControlLabel
            value="deactivate"
            control={
              <Radio
                color="theme"
                sx={{
                  color: theme.palette.theme.main,
                  "&.Mui-checked": {
                    color: theme.palette.theme.main,
                  },
                }}
              />
            }
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                Deactivate
              </span>
            }
          />
        </RadioGroup>
      </FormControl>
      <ReminderDialog
        open={isOpenConfirmModal}
        onClose={closeConfirmModal}
        onConfirm={handleConfirm}
        header={"Are you sure?"}
        reminder={"Are you sure you want to change status?"}
        confirm={"Confirm"}
      />
    </Fragment>
  );
};
