import { Box, Stack } from "@mui/material";
import { Fragment, useState } from "react";
import UsersTable from "../../components/table/UsersTable";
import { UsersHeader } from "../../components/headers/UsersHeader";
import { useUsersStore } from "../../store/store";
import { useModal } from "../../hooks";
import { AddAdminDialog } from "../../components/dialogs/AddAdminDialog";

export const UsersScreen = () => {
  const {
    tabValue,
    setTabValue,
    catID,
    setCatID,
    searchInput,
    setSearchInput,
    setShouldReload,
  } = useUsersStore((state) => state);

  const [isAddAdminModal, openAddAdminModal, closeAddAdminModal] = useModal();

  const onClickAddAdmin = () => {
    openAddAdminModal();
  };

  return (
    <Fragment>
      <Stack direction={"column"} sx={{ pr: 2 }}>
        <UsersHeader
          showDropdown={tabValue === 0}
          onDropdownChange={(catID) => {
            setCatID(catID);
            setShouldReload(true);
          }}
          onSearchChange={(text) => {
            setSearchInput(text);
            setShouldReload(true);
          }}
          catID={catID}
        />
        <Box sx={{ width: "100%", mt: 1, mb: 1 }} />
        <UsersTable
          onTabChange={(tab) => {
            setTabValue(tab);
          }}
          catID={catID}
          searchInput={searchInput}
          onClickAddAdmin={onClickAddAdmin}
        />
      </Stack>
      {isAddAdminModal && (
        <AddAdminDialog
          open={isAddAdminModal}
          onClose={closeAddAdminModal}
          // onSubmit={onCreatedNewUser}
        />
      )}
    </Fragment>
  );
};
