import { Grid, Stack, useTheme } from "@mui/material";
import { AboutCompany } from "../../components/data/AboutCompany";
import { Documents } from "../../components/data/Documents";
import { SellerCompany } from "../../components/data/SellerCompany";
import { SellerCompanyTimeStamp } from "../../components/data/SellerCompanyTimeStamp";
import { useState, useEffect, Fragment } from "react";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { useParams } from "react-router-dom";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { EmptyCard } from "../../components/emptyCard/EmptyCard";

export const CompanyDetailsSellerScreen = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [companyDetails, setCompanyDetails] = useState(null);

  const getCompanyDetails = () => {
    api
      .get(`${urls.registrationRequestDetail}${id}`)
      .then((res) => {
        setCompanyDetails(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  const handleStatusUpdate = (status) => {
    let params = {
      registration_status: status,
    };

    api
      .post(urls.approveRejectUser + id, params)
      .then((res) => {
        showToast(res.data.message, "success");
        getCompanyDetails();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleAccessChange = (status) => {
    console.log(status);
    handleStatusUpdate(status === "active" ? true : false);
  };

  return (
    <Fragment>
      {isLoading && !companyDetails && <LoaderCard />}
      {!isLoading && !companyDetails && <EmptyCard text="No Details" />}
      {companyDetails && (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Stack direction={"column"} sx={{ gap: 2 }}>
              <AboutCompany data={companyDetails} />
              <Documents
                data={companyDetails?.user_certificates ?? []}
                userID={companyDetails?.id}
                onChange={() => getCompanyDetails()}
                userType="Seller"
              />
            </Stack>
          </Grid>
          <Grid item xs>
            <Stack direction={"column"} sx={{ gap: 2 }}>
              <SellerCompany
                details={companyDetails}
                onStatusChange={handleAccessChange}
              />
              <SellerCompanyTimeStamp date={companyDetails?.joined_on} />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
