import {
  Button,
  Dialog,
  Divider,
  Grid,
  List,
  ListItemButton,
  Stack,
  TextField,
  Typography,
  useTheme,
  Backdrop,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ProductSettingsCard } from "../cards/ProductSettingsCard";
// import EditAttributes, {AddAttributes} from "../alert/AddAttributes"
import { Fragment, useEffect, useState } from "react";
import { AddItem } from "../alert/AddItem";
import { sampleProductAttributes } from "../../utilities";
// import api from "../../service/api";
// import urls from "../../service/urls";
import { useAuth, useToast } from "../../context";
import { useNavigate } from "react-router-dom";
import { AddAttributes } from "../alert/AddAttributes";
import { EditAttributes } from "../alert/EditAttributes";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { LoaderCard } from "../loaders/LoaderCard";
import { useAPI } from "../../hooks/useAPI";
import { EditItem } from "../alert/EditItem";
import { useModal } from "../../hooks";

export const ProductSettingsMenu = (props) => {
  const { item } = props;
  const theme = useTheme();
  const [openAttributeDialog, setOpenAttributeDialog] = useState();
  const [editId, setEditId] = useState();
  const [removeId, setRemoveId] = useState();
  const [removeName, setRemoveName] = useState();
  const [editText, setEditText] = useState();
  const [openEditAttribute, setOpenEditAttribute] = useState();
  const [openRemoveAttribute, setOpenRemoveAttribute] = useState();
  const [openAddItemDialog, setOpenAddItemDialog] = useState();
  const [attribute, setAttribute] = useState([]);
  const [attributeItem, setAttributeItem] = useState([]);
  const [itemName, setItemName] = useState();
  const [attributelist, setAttributeList] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [attributeItemList, setAttributeItemList] = useState([]);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const [isOpenEditItem, openEditItem, closeEditItem] = useModal();
  const [selItem, setSelItem] = useState(null);
  const [subItemToRemove, setSubItemToRemove] = useState(null);

  const handleOpenAttributeDialog = () => {
    setOpenAttributeDialog(true);
  };
  // const handleEditDialog = () => {

  // };
  const handleCloseAttributeDialog = () => {
    setOpenAttributeDialog(false);
  };
  const handleCloseEditAttributeDialog = () => {
    setOpenEditAttribute(false);
  };
  const handleRemoveAttributeDialog = () => {
    setOpenRemoveAttribute(false);
  };
  const handleOpenAddItemDialog = () => {
    setOpenAddItemDialog(true);
  };

  const handleCloseAddItemDialog = () => {
    setOpenAddItemDialog(false);
  };
  const removeElement = () => {
    if (itemToRemove !== null) {
      // const newattribute = attribute.filter((item) => item.id !== itemToRemove);
      // setAttribute(newattribute);
      // setItemToRemove(null);
      api
        .delete(urls.removeAttribute + itemToRemove)
        .then((res) => {
          showToast(res.data.message, "success");
          getAttribute();
          setItemToRemove(null);
          setSelectedItem(null);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            logout();
          } else {
            showToast(err.response.data?.message, "error");
          }
        });
    }
  };

  const removeSubElement = () => {
    if (subItemToRemove !== null) {
      // const newattribute = attribute.filter((item) => item.id !== itemToRemove);
      // setAttribute(newattribute);
      // setItemToRemove(null);
      api
        .delete(urls.removeAttributeDescription + subItemToRemove)
        .then((res) => {
          showToast(res.data.message, "success");
          getAttributeItem(selectedItem.id);
          setSubItemToRemove(null);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            logout();
          } else {
            showToast(err.response.data?.message, "error");
          }
        });
    }
  };

  const openDialog = (item) => {
    setItemToRemove(item);
  };

  // AttributeList
  const getAttribute = () => {
    const params = {
      ...attribute,
    };
    api
      .get(urls.attributes, params)
      .then((res) => {
        setAttribute(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    getAttribute();
  }, []);

  //Item List

  // const attributeItem = `${urls.attributeItems}/${id}`;
  const getAttributeItem = (id) => {
    api
      .get(`${urls.attributeItems}/${id}`)
      .then((res) => {
        setAttributeItemList(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response.data.message, "error");
        }
      });
  };

  // useEffect(() => {
  //   if (attribute !== null) {
  //     getAttributeItem();
  //   }
  // }, []);

  const itemList = (item) => {
    setItemName(item);
  };
  // const attributeArray = Object.values(attributeItemList);

  const listItemButtonStyle = {
    borderRadius: 2,
    p: 0,
    "&.Mui-selected": {
      backgroundColor: theme.palette.menuSel.main,
      "&:hover": {
        backgroundColor: theme.palette.menuSel.main,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.menuSelLight.main,
    },
  };
  const handleItemClick = (item) => {
    // If the clicked item is the same as the currently selected item,
    // deselect it by setting selectedItem to null.
    // Otherwise, select the clicked item.
    // setSelectedItem(item === selectedItem ? null : item);
    setSelectedItem(item);
  };
  // const handleBlur = (item)=>{
  //   setSelectedItems(false)
  // }

  return (
    <Stack direction={"row"} alignItems={"flex-start"}>
      {openAttributeDialog && (
        <AddAttributes
          openAttributeDialog={openAttributeDialog}
          onClose={handleCloseAttributeDialog}
          fetchData={getAttribute}
        />
      )}
      <EditAttributes
        openEditAttribute={openEditAttribute}
        onClose={handleCloseEditAttributeDialog}
        editId={editId}
        editText={editText}
        fetchData={() => {
          setSelectedItem(null);
          getAttribute();
        }}
        // itemId={item.id}
      />

      {/* <RemoveAttribute
         openRemoveAttribute={openRemoveAttribute}
         onClose={handleRemoveAttributeDialog}
          item={itemToRemove}
          onConfirm={removeElement}
      /> */}
      {itemToRemove && (
        <ReminderDialog
          open={() => {
            openDialog();
          }}
          onClose={() => {
            setItemToRemove(null);
          }}
          onConfirm={removeElement}
          header={"Remove"}
          reminder={"Are you sure you want to remove?"}
          confirm={"Remove"}
        />
      )}
      {subItemToRemove && (
        <ReminderDialog
          open={() => {
            openDialog();
          }}
          onClose={() => {
            setSubItemToRemove(null);
          }}
          onConfirm={removeSubElement}
          header={"Remove"}
          reminder={"Are you sure you want to remove?"}
          confirm={"Remove"}
        />
      )}
      <Grid
        container
        direction={"column"}
        xs={4}
        sx={{
          bgcolor: theme.palette.menuHeader.main,
          borderRadius: 2,
        }}
      >
        <Grid item>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ height: 60, pl: 2, pr: 2 }}
          >
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                Attribute
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={handleOpenAttributeDialog}
                variant="contained"
                disableElevation
                color="theme"
                sx={{ fontSize: 14, fontWeight: 500, textTransform: "none" }}
              >
                Add Attributes
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {isLoading && attribute.length === 0 && <LoaderCard />}
          <List sx={{ p: 0 }}>
            {attribute?.map((item, i) => (
              <ListItemButton sx={listItemButtonStyle} key={i}>
                <Stack
                  direction={"column"}
                  sx={{
                    width: "100%",
                    height: 55,
                    backgroundColor:
                      item === selectedItem
                        ? theme.palette.lightblue.main
                        : theme.palette.white.main,
                    "&:hover": {
                      backgroundColor: theme.palette.lightblue.main,
                    },
                  }}
                  onClick={() => {
                    handleItemClick(item);
                  }}
                >
                  <Divider />
                  <Stack
                    onClick={() => {
                      setAttributeItemList([]);
                      itemList(item.name);
                      getAttributeItem(item.id);
                    }}
                    //onBlur={handleBlur}
                    direction={"row"}
                    sx={{
                      pl: 2,
                      pr: 2,
                      height: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {
                      <Typography
                        onClick={() => {
                          setEditId(item.id);
                        }}
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        {item.name}
                      </Typography>
                    }
                    <Stack direction={"row"}>
                      <Typography
                        onClick={() => {
                          setEditId(item.id);
                          setEditText(item.name);
                          setOpenEditAttribute(true);
                        }}
                        sx={{
                          color: theme.palette.theme.main,
                          fontSize: 16,
                          fontWeight: 500,
                          textTransform: "none",
                        }}
                      >
                        Edit
                        {/* <EditAttributes
                          openEditAttribute={openEditAttribute}
                          onClose={handleCloseEditAttributeDialog}
                        /> */}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ ml: "12px", mr: "12px", mt: "4px", mb: "4px" }}
                      />
                      <Typography
                        onClick={() => {
                          //setItemToRemove(item.id, item.name);
                          // setItemToRemove(item.name);
                          //removeElement(i);
                          openDialog(item.id);
                          // setItemToRemove(true);
                          // setOpenRemoveAttribute(true);
                        }}
                        sx={{
                          color: theme.palette.error.main,
                          fontSize: 16,
                          fontWeight: 500,
                          textTransform: "none",
                        }}
                      >
                        Remove
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </ListItemButton>
            ))}
          </List>
        </Grid>
      </Grid>
      {selectedItem && (
        <Grid
          container
          direction={"column"}
          xs={8}
          sx={{
            bgcolor: theme.palette.menuHeader.main,
            borderRadius: 2,
            ml: 2,
          }}
        >
          <Grid item>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ height: 60, pl: 2, pr: 2 }}
            >
              {/* {attribute?.map((item, i) => ( */}
              <Grid item>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {itemName}
                </Typography>
              </Grid>
              {/* ))} */}
              <Grid item>
                <Button
                  onClick={handleOpenAddItemDialog}
                  variant="contained"
                  disableElevation
                  color="theme"
                  sx={{ fontSize: 14, fontWeight: 500, textTransform: "none" }}
                >
                  Add Item
                </Button>
                {openAddItemDialog && (
                  <AddItem
                    openAddItemDialog={openAddItemDialog}
                    onClose={handleCloseAddItemDialog}
                    attrID={selectedItem.id}
                    fetchData={() => getAttributeItem(selectedItem.id)}
                  />
                )}
                {isOpenEditItem && (
                  <EditItem
                    openAddItemDialog={isOpenEditItem}
                    onClose={closeEditItem}
                    fetchData={() => getAttributeItem(selectedItem.id)}
                    data={selItem}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {selectedItem && isLoading && attributeItemList.length === 0 && (
            <LoaderCard />
          )}
          {attributeItemList?.map((item, i) => (
            <Grid item key={i}>
              <ProductSettingsCard
                name={item.name}
                imgitem={item.image}
                onEditClick={(e) => {
                  setSelItem(item);
                  openEditItem();
                }}
                onDeleteClick={() => setSubItemToRemove(item.id)}
              />
              {/* <ProductSettingsCard />
            <ProductSettingsCard /> */}
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
};
