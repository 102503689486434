import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import SearchField from "../../components/search/SearchField";
import { EmptyData } from "../../components/empty_placeholder/EmptyData";
import { empData } from "../../assets";
import { Add } from "iconsax-react";
import { useState, useEffect, useCallback, useMemo } from "react";
import { BrandMenu } from "../../components/menu/BrandMenu";
import { CategoryMenu } from "../../components/menu/CategoryMenu";
import { SubCategoryMenu } from "../../components/menu/SubCategoryMenu";
import { EmptyMenu } from "../../components/empty_placeholder/EmptyMenu";
import { ProductMenu } from "../../components/menu/ProductMenu";
import { useAPI } from "../../hooks/useAPI";
import { useAuth, useToast } from "../../context";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { debounce } from "lodash";
import { AddBrands } from "../../components/alert/AddBrands";

export const ProductScreen = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();

  const [addBrand, setAddBrand] = useState(false);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryTitles, setSubcategoryTitles] = useState([]);
  const [parentCategoryIDs, setParentCategoryIDs] = useState([]);
  const [parentHiddenStatuses, setParentHiddenStatuses] = useState([]);
  const [subCategoryIDs, setSubcategoryIDs] = useState([]);
  const [prodCategory, setProdCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [selSubCat, setSelSubCat] = useState("");

  const getBrands = useCallback(async () => {
    try {
      const res = await api.get(urls.brandsList);
      setBrands(res.data.data);
    } catch (err) {
      if (err.response?.status === 401) {
        logout();
      } else {
        showToast(err.response?.data?.message, "error");
      }
    }
  }, [api, urls.brandsList, logout, showToast]);

  const getCategories = useCallback(
    async (id) => {
      try {
        const res = await api.get(`${urls.categoriesList}${id}`);
        setCategories(res.data.data);
        if (selectedCategory) {
          handleCategoryClick(
            res.data.data.find((c) => c.id === selectedCategory.id)
          );
        }
      } catch (err) {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      }
    },
    [api, urls.categoriesList, selectedCategory, logout, showToast]
  );

  const getProducts = useCallback(
    async (id) => {
      try {
        const res = await api.get(`${urls.productsList}${id}`);
        setProducts(res.data.data);
      } catch (err) {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      }
    },
    [api, urls.productsList, logout, showToast]
  );

  useEffect(() => {
    getBrands();
  }, [getBrands]);

  useEffect(() => {
    setProducts([]);
    if (prodCategory) {
      getProducts(prodCategory);
    }
  }, [prodCategory, getProducts]);

  const handleBrandClick = useCallback(
    (brandID) => {
      setSelectedBrand(brandID);
      getCategories(brandID);
      setCategories([]);
      setSelectedCategory(null);
      setSubCategories([]);
      setProdCategory("");
    },
    [getCategories]
  );

  const handleCategoryClick = useCallback((category, clearSubs = true) => {
    setSelectedCategory(category);
    setSubCategories(category ? [category.sub_categories] : []);
    setSubcategoryTitles(category ? [category.name] : []);
    setParentCategoryIDs(category ? [category.id] : []);
    setParentHiddenStatuses(category ? [!category.status] : []);
    if (clearSubs) {
      setSubcategoryIDs([]);
    }
    setProdCategory(category?.sub_categories.length === 0 ? category?.id : "");
  }, []);

  const handleSubcategoryClick = useCallback((category, index) => {
    setSelSubCat(category.id);
    setSubCategories((prev) => {
      const curr = prev.slice(0, index + 1);
      curr.push(category.sub_categories);
      return curr;
    });
    setSubcategoryTitles((prev) => {
      const curr = prev.slice(0, index + 1);
      curr.push(category.name);
      return curr;
    });
    setParentCategoryIDs((prev) => {
      const curr = prev.slice(0, index + 1);
      curr.push(category.id);
      return curr;
    });
    setParentHiddenStatuses((prev) => {
      const curr = prev.slice(0, index + 1);
      curr.push(!category.status);
      return curr;
    });
    setSubcategoryIDs((prev) => {
      const curr = prev.slice(0, index);
      curr.push(category.id);
      return curr;
    });
    setProdCategory(category.sub_categories.length === 0 ? category.id : "");
  }, []);

  // Debounce the handleCategoryClick and handleSubcategoryClick to prevent excessive API calls
  const debouncedHandleCategoryClick = useMemo(
    () => debounce(handleCategoryClick, 300),
    [handleCategoryClick]
  );
  const debouncedHandleSubcategoryClick = useMemo(
    () => debounce(handleSubcategoryClick, 300),
    [handleSubcategoryClick]
  );

  useEffect(() => {
    return () => {
      debouncedHandleCategoryClick.cancel();
      debouncedHandleSubcategoryClick.cancel();
    };
  }, [debouncedHandleCategoryClick, debouncedHandleSubcategoryClick]);

  const renderBrandMenu = useMemo(
    () => (
      <BrandMenu
        data={brands}
        onBrandClick={handleBrandClick}
        selectedID={selectedBrand}
        onBrandsChange={getBrands}
      />
    ),
    [brands, handleBrandClick, selectedBrand, getBrands]
  );

  const renderCategoryMenu = useMemo(
    () => (
      <CategoryMenu
        data={categories}
        onCategoryClick={(cat) => debouncedHandleCategoryClick(cat, true)}
        selectedID={selectedCategory?.id}
        brandID={selectedBrand}
        onCategoriesChange={() => getCategories(selectedBrand)}
      />
    ),
    [
      categories,
      debouncedHandleCategoryClick,
      selectedCategory,
      selectedBrand,
      getCategories,
    ]
  );

  return (
    <Grid container direction="column">
      <Grid item sx={{ mr: 2 }}>
        <Grid
          container
          direction="row"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.primary.main,
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              Products
            </Typography>
          </Grid>
          {/* <Grid item>
            <SearchField onSearchTextChange={() => {}} />
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item>
        <Divider sx={{ mt: 1, mr: 2 }} />
      </Grid>
      {isLoading && brands.length === 0 && <LoaderCard />}
      {!isLoading && brands.length === 0 && (
        <Grid item xs sx={{ mt: 2, display: "flex", pr: 2, minHeight: "70vh" }}>
          <EmptyData
            empImage={empData}
            empText="There are no categories available at the moment, Please feel free to add one."
            btnIcon={<Add />}
            btnText="Add Brand"
            setAddBrand={setAddBrand}
          />
        </Grid>
      )}
      {brands.length > 0 && (
        <Grid item xs sx={{ mt: 2 }}>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{
              pr: 2,
              width: "93vw",
              flexWrap: "inherit",
              overflowX: "scroll",
            }}
          >
            <Grid item>{renderBrandMenu}</Grid>
            {selectedBrand && (
              <Grid item>
                {categories.length === 0 ? (
                  <EmptyMenu
                    isCategory={true}
                    isLoading={isLoading}
                    brandID={selectedBrand}
                    onCategoriesChange={() => getCategories(selectedBrand)}
                    isParentHidden={false}
                  />
                ) : (
                  renderCategoryMenu
                )}
              </Grid>
            )}
            {subCategories.length > 0 &&
              subCategories.map((cat, i) => (
                <Grid item key={i}>
                  {cat?.length > 0 ? (
                    <SubCategoryMenu
                      data={cat}
                      onSubCategoryClick={(subCat) =>
                        debouncedHandleSubcategoryClick(subCat, i)
                      }
                      title={subCategoryTitles[i]}
                      selectedID={subCategoryIDs[i]}
                      onSubcategoryChange={(delProdCat) => {
                        if (delProdCat) {
                          setProdCategory("");
                        }
                        getCategories(selectedBrand);
                      }}
                      // catID={
                      //   subCategoryIDs.length > 1
                      //     ? subCategoryIDs[i - 1]
                      //     : selectedCategory?.id
                      // }
                      catID={parentCategoryIDs[i]}
                      isParentHidden={parentHiddenStatuses[i]}
                    />
                  ) : products.length === 0 ? (
                    <EmptyMenu
                      catID={
                        subCategoryIDs.length > 0
                          ? subCategoryIDs[i - 1]
                          : selectedCategory?.id
                      }
                      isLoading={isLoading}
                      mainCatID={selectedCategory.id}
                      onCategoriesChange={() => getCategories(selectedBrand)}
                      onAddFirstProduct={() =>
                        getProducts(
                          selSubCat ? selSubCat : selectedCategory?.id
                        )
                      }
                      isParentHidden={parentHiddenStatuses[i]}
                    />
                  ) : (
                    <ProductMenu
                      data={products}
                      catID={prodCategory}
                      mainCatID={selectedCategory.id}
                      onProductsChange={() => getProducts(prodCategory)}
                      isParentHidden={parentHiddenStatuses[i]}
                      onProductChange={() => {
                        // setProducts([]);
                        // getProducts(prodCategory);
                      }}
                    />
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>
      )}
      {addBrand && (
        <AddBrands
          open={addBrand}
          onClose={() => setAddBrand(false)}
          onSubmit={() => {
            getBrands();
            setAddBrand(false);
          }}
        />
      )}
    </Grid>
  );
};
