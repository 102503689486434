import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { BuyersTable } from "./BuyersTable";
import { SellersTable } from "./SellersTable";
import { AdminsTable } from "./AdminsTable";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { useUsersStore } from "../../store/store";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 2, pr: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  tab: {
    "&.MuiTab-root": {
      textTransform: "none",
      fontSize: 18,
      fontWeight: 500,
      color: "#7A7A7A",

      "&.Mui-selected": {
        color: "black",
      },
    },
  },
  tableborder: {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
  },
}));

export default function BasicTabs(props) {
  const { onTabChange, catID, searchInput, onClickAddAdmin } = props;
  const theme = useTheme();
  const classes = useStyles();
  // const [value, setValue] = useState(0);
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  // const [listCount, setListCount] = useState({
  //   buyers: 0,
  //   sellers: 0,
  // });
  const { value, setValue, listCount, setListCount, resetTab } = useUsersStore(
    (state) => state
  );

  const handleTabChange = (e, v) => {
    setValue(v);
    onTabChange(v);
    resetTab();
  };

  useEffect(() => {
    console.log(listCount);
  }, [listCount]);

  return (
    <Box sx={{ width: "100%" }} className={classes.tableborder}>
      <Stack
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          pt: 2,
          px: 2,
          alignContent: "center",
          justifyContent: "space-between",
        }}
        direction={"row"}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.theme.main,
            },
          }}
        >
          <Tab
            label={
              listCount.buyers === 0 ? "Buyers" : `Buyers (${listCount.buyers})`
            }
            sx={{
              textTransform: "none",
              color: theme.palette.tabgrey.main,
              fontSize: 18,
              fontWeight: 500,
              "&.Mui-selected": {
                color: theme.palette.black.main, // Color for the selected text
              },
            }}
          />
          <Tab
            label={
              listCount.sellers === 0
                ? "Sellers"
                : `Sellers (${listCount.sellers})`
            }
            sx={{
              textTransform: "none",
              color: theme.palette.tabgrey.main,
              fontSize: 18,
              fontWeight: 500,
              "&.Mui-selected": {
                color: theme.palette.black.main, // Color for the selected text
              },
            }}
          />
          {/* <Tab
            label={
              listCount.sellers === 0
                ? "Admins"
                : `Admins (${listCount.admins})`
            }
            sx={{
              textTransform: "none",
              color: theme.palette.tabgrey.main,
              fontSize: 18,
              fontWeight: 500,
              "&.Mui-selected": {
                color: theme.palette.black.main, // Color for the selected text
              },
            }}
          /> */}
        </Tabs>
        {/* {value === 2 && (
          <Button
            variant="contained"
            size="medium"
            disableElevation
            color="theme"
            sx={{ height: "40px", textTransform: "none" }}
            onClick={onClickAddAdmin}
          >
            Add User
          </Button>
        )} */}
      </Stack>
      <CustomTabPanel value={value} index={0}>
        <BuyersTable
          onCountChange={(count) => setListCount(count)}
          catID={catID}
          searchInput={searchInput}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SellersTable
          onCountChange={(count) => setListCount(count)}
          searchInput={searchInput}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <AdminsTable
          onCountChange={(count) => setListCount(count)}
          searchInput={searchInput}
        />
      </CustomTabPanel>
    </Box>
  );
}

BasicTabs.propTypes = {
  onTabChange: PropTypes.func,
  catID: PropTypes.string,
  searchInput: PropTypes.string,
  onClickAddAdmin: PropTypes.func,
};
