import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { NotificationSettingsRadio } from "../radio/NotificationSettingsRadio";
import { TradingCategoryCard } from "../cards/TradingCategoryCard";
import { useState, useEffect } from "react";
import { AddCategory } from "../alert/AddCategory";
import { EditPlatformCurrency } from "../alert/EditPlatformCurrency";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import moment from "moment";
import { EditCutoffTime } from "../alert/EditCutoffTime";
import { useModal } from "../../hooks";
import { LoaderCard } from "../loaders/LoaderCard";
import { ReminderDialog } from "../dialogs/ReminderDialog";

export const SettingsMenu = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [tradeCategories, setTradeCategories] = useState([]);
  const [settings, setSettings] = useState(null);
  const [isOpenEditCutoffDialog, openCutoffDialog, closeCutoffDialog] =
    useModal();
  const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] = useModal();
  const [selCategory, setSelCategory] = useState(null);

  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
  const [openEditPlatformCurrencyDialog, setOpenEditPlatformCurrencyDialog] =
    useState();
  const [notifParams, setNotifParams] = useState({
    user_type: "all",
    message: "",
  });

  const handleOpenAddCategoryDialog = () => {
    setOpenAddCategoryDialog(true);
  };

  const handleCloseAddCategoryDialog = () => {
    setOpenAddCategoryDialog(false);
    setSelCategory(null);
  };

  const handleOpenEditPlatformCurrencyDialog = () => {
    setOpenEditPlatformCurrencyDialog(true);
  };

  const handleCloseEditPlatformCurrencyDialog = () => {
    setOpenEditPlatformCurrencyDialog(false);
  };

  const getTradeCategories = () => {
    api
      .get(urls.tradeCategories)
      .then((res) => {
        setTradeCategories(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getSettings = () => {
    api
      .get(urls.settingsList)
      .then((res) => {
        setSettings(res.data.data.settings);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getTradeCategories();
    getSettings();
  }, []);

  const handleRemoveClick = (item) => {
    setSelCategory(item);
    openDeleteDialog();
  };

  const handleEditClick = (item) => {
    setSelCategory(item);
    handleOpenAddCategoryDialog();
  };

  const handleSendNotification = () => {
    api
      .post(urls.sendNotification, { ...notifParams })
      .then((res) => {
        showToast(res.data.message, "success");
        setNotifParams({ ...notifParams, message: "" });
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleDeleteCategory = () => {
    api
      .delete(urls.removeTradeCategory + selCategory.id)
      .then((res) => {
        showToast(res.data.message, "success");
        getTradeCategories();
        closeDeleteDialog();
        setSelCategory(null);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <Stack direction={"row"} alignItems={"flex-start"}>
      <Grid
        container
        direction={"column"}
        xs={8}
        sx={{
          mr: 2,
          border: 1,
          borderColor: theme.palette.line.main,
          borderRadius: 2,
        }}
      >
        <Grid item>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ height: 60, pl: 2, pr: 2 }}
          >
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Send Notification
              </Typography>
            </Grid>
            <Grid item>
              <NotificationSettingsRadio
                value={notifParams.user_type}
                onChange={(e) =>
                  setNotifParams({ ...notifParams, user_type: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Stack direction={"column"} sx={{ p: 2 }}>
            <TextField
              multiline
              placeholder="Type your message here"
              variant="standard"
              type="text"
              rows={10}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: theme.palette.placeholder.main,
                    opacity: 1, // otherwise firefox shows a lighter color
                  },
                  fontSize: 16,
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                },
              }}
              InputProps={{ disableUnderline: true }}
              sx={{
                width: "96%",
                p: 2,
                bgcolor: theme.palette.menuAlt.main,
                borderRadius: 2,
              }}
              value={notifParams.message}
              onChange={(e) => {
                setNotifParams({ ...notifParams, message: e.target.value });
              }}
            />
            <Button
              variant="contained"
              disableElevation
              color="theme"
              sx={{
                fontSize: 16,
                fontWeight: 500,
                width: 180,
                height: 45,
                mt: 2,
                textTransform: "none",
              }}
              disabled={!notifParams.message}
              onClick={handleSendNotification}
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid container direction={"column"} xs={4}>
        <Grid item>
          <Grid
            container
            direction={"column"}
            sx={{
              border: 1,
              borderColor: theme.palette.line.main,
              borderRadius: 2,
            }}
          >
            <Grid item>
              <Grid
                container
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ height: 60, pl: 2, pr: 2 }}
              >
                <Grid item>
                  <Typography
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    Trade Categories
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleOpenAddCategoryDialog}
                    variant="contained"
                    disableElevation
                    color="theme"
                    sx={{
                      fontSize: 14,
                      fontWeight: 500,
                      textTransform: "none",
                    }}
                  >
                    Add Category
                  </Button>
                  {openAddCategoryDialog && (
                    <AddCategory
                      openAddCategoryDialog={openAddCategoryDialog}
                      onClose={handleCloseAddCategoryDialog}
                      onSubmit={getTradeCategories}
                      data={selCategory}
                      isEdit={Boolean(selCategory)}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              {isLoading && tradeCategories.length === 0 && <LoaderCard />}
              {tradeCategories.map((category, index) => (
                <TradingCategoryCard
                  key={index}
                  name={category.name}
                  onRemoveClick={() => handleRemoveClick(category)}
                  onEditClick={() => handleEditClick(category)}
                  showDivider={index !== tradeCategories.length - 1}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        {settings && (
          <Grid item>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                p: 2,
                mt: 2,
                border: 1,
                borderColor: theme.palette.line.main,
                borderRadius: 2,
              }}
            >
              <Stack direction={"column"} sx={{ gap: 1 }}>
                <Typography
                  sx={{
                    color: theme.palette.tertiary.main,
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  Platform Currency
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  {settings?.currency}
                </Typography>
              </Stack>

              <Button
                onClick={handleOpenEditPlatformCurrencyDialog}
                variant="text"
                color="theme"
                sx={{ fontSize: 16, fontWeight: 500 }}
              >
                Edit
              </Button>
              {openEditPlatformCurrencyDialog && (
                <EditPlatformCurrency
                  openEditPlatformCurrencyDialog={
                    openEditPlatformCurrencyDialog
                  }
                  onClose={handleCloseEditPlatformCurrencyDialog}
                  currency={settings?.currency}
                  onSubmit={getSettings}
                />
              )}
            </Stack>
          </Grid>
        )}
        {settings && (
          <Grid item>
            <Stack
              direction={"column"}
              sx={{
                mt: 2,
                border: 1,
                borderColor: theme.palette.line.main,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: 60,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    pl: 2,
                    pr: 2,
                    color: theme.palette.primary.main,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Order Cut - Off Time (GST)
                </Typography>
              </Box>
              <Divider />
              <Stack
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
                sx={{ height: 60, pl: 2, pr: 2 }}
              >
                <Box
                  sx={{
                    bgcolor: theme.palette.badgePrimary.main,
                    height: 40,
                    pl: 2,
                    pr: 2,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 6,
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.white.main,
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    {moment(settings?.cut_off, "HH").format("h:mm a")}
                  </Typography>
                </Box>
                <Button
                  variant="text"
                  color="theme"
                  sx={{ fontSize: 16, fontWeight: 500 }}
                  onClick={openCutoffDialog}
                >
                  Edit
                </Button>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
      {isOpenEditCutoffDialog && (
        <EditCutoffTime
          open={isOpenEditCutoffDialog}
          onClose={closeCutoffDialog}
          time={settings?.cut_off}
          onSubmit={getSettings}
        />
      )}
      {isOpenDeleteDialog && (
        <ReminderDialog
          open={isOpenDeleteDialog}
          onClose={() => {
            closeDeleteDialog();
            setSelCategory(null);
          }}
          onConfirm={handleDeleteCategory}
          header={"Remove"}
          reminder={"Are you sure to remove this trade category?"}
          confirm={"Remove"}
        />
      )}
    </Stack>
  );
};
