import { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditDel from "../popup/EditDel";
import EditDeleteMenu from "../menu/EditDeleteMenu";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    padding: "20px",
    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
  headertable: {
    fontWeight: 500,
    color: "#000",
  },
  tableitem: {
    color: "#1F1F1F",
  },
  tableborder: {
    border: "1px solid #D9CFCF",
    borderRadius: "10px",
    borderCollapse: "unset !important",
    justifyContent: "left",
    padding: "20px",
  },
}));

export const BuyersUsersTable = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function createData(id, date, name, role, email) {
    return { id, date, name, role, email };
  }

  const data = [
    createData(
      "1",
      "updated on 23 Nov 2023",
      "Alvin Nolan",
      "Buyer",
      "alvinyop@gmail.com"
    ),
    createData(
      "2",
      "updated on 23 Nov 2023",
      "Alvin Nolan",
      "Buyer",
      "alvinyop@gmail.com"
    ),
    createData(
      "3",
      "updated on 23 Nov 2023",
      "Alvin Nolan",
      "Buyer",
      "alvinyop@gmail.com"
    ),
    createData(
      "4",
      "updated on 23 Nov 2023",
      "Alvin Nolan",
      "Buyer",
      "alvinyop@gmail.com"
    ),
  ];

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    height: 45,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
  };

  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.line.main,
        borderRadius: 2,
        pl: 2,
        pr: 2,
      }}
    >
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyle}>No.</TableCell>
              <TableCell sx={headerStyle}>Date</TableCell>
              <TableCell sx={headerStyle}>Name</TableCell>
              <TableCell sx={headerStyle}>Role</TableCell>
              <TableCell sx={headerStyle}>Email</TableCell>
              <TableCell sx={headerStyle}></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((data) => (
              <TableRow>
                <TableCell sx={itemStyle}>{data.id}</TableCell>
                <TableCell sx={itemStyle}>{data.date}</TableCell>
                <TableCell sx={itemStyle}>{data.name}</TableCell>
                <TableCell sx={itemStyle}>{data.role}</TableCell>
                <TableCell sx={itemStyle}>{data.email}</TableCell>
                <TableCell sx={{ cursor: "pointer" }}>
                  <EditDeleteMenu
                    alignItems={"center"}
                    onEditClick={() => {}}
                    onDeleteClick={() => {}}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>{" "}
      <TablePagination
        component="div"
        align="right"
        count={12}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: "12px", mb: "12px" }}
      />
    </Box>
  );
};
