import { useTheme } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { useModal } from "../../hooks";
import { Fragment, useState } from "react";

export const RequestDetailRadio = (props) => {
  const { userType, status, onStatusChange, disabled } = props;
  const theme = useTheme();
  const [isOpenConfirmModal, openConfirmModal, closeConfirmModal] = useModal();
  const [toStatus, setToStatus] = useState("");

  const handleConfirm = () => {
    closeConfirmModal();
    onStatusChange(toStatus);
  };

  return (
    <Fragment>
      {userType === "Buyer" ? (
        <FormControl disabled={disabled}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="deactivate"
            name="radio-buttons-group"
            value={status}
            onChange={(e) => {
              openConfirmModal();
              setToStatus(e.target.value);
            }}
          >
            <FormControlLabel
              value="full_access"
              control={
                <Radio
                  sx={{
                    color: theme.palette.theme.main,
                    "&.Mui-checked": {
                      color: theme.palette.theme.main,
                    },
                  }}
                />
              }
              label="Full Access"
            />
            <FormControlLabel
              value="view_only"
              control={
                <Radio
                  sx={{
                    color: theme.palette.theme.main,
                    "&.Mui-checked": {
                      color: theme.palette.theme.main,
                    },
                  }}
                />
              }
              label="View Only"
            />
            <FormControlLabel
              value="deactivate"
              control={
                <Radio
                  sx={{
                    color: theme.palette.theme.main,
                    "&.Mui-checked": {
                      color: theme.palette.theme.main,
                    },
                  }}
                />
              }
              label="Deactivate"
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <FormControl disabled={disabled}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="deactivate"
            name="radio-buttons-group"
            value={status}
            onChange={(e) => {
              openConfirmModal();
              setToStatus(e.target.value);
            }}
          >
            <FormControlLabel
              value="active"
              control={
                <Radio
                  sx={{
                    color: theme.palette.theme.main,
                    "&.Mui-checked": {
                      color: theme.palette.theme.main,
                    },
                  }}
                />
              }
              label="Active"
            />
            <FormControlLabel
              value="deactivate"
              control={
                <Radio
                  sx={{
                    color: theme.palette.theme.main,
                    "&.Mui-checked": {
                      color: theme.palette.theme.main,
                    },
                  }}
                />
              }
              label="Deactivate"
            />
          </RadioGroup>
        </FormControl>
      )}
      <ReminderDialog
        open={isOpenConfirmModal}
        onClose={closeConfirmModal}
        onConfirm={handleConfirm}
        header={"Are you sure?"}
        reminder={"Are you sure you want to change status?"}
        confirm={"Confirm"}
      />
    </Fragment>
  );
};

RequestDetailRadio.propTypes = {
  userType: PropTypes.string,
  status: PropTypes.string,
  onStatusChange: PropTypes.func,
  disabled: PropTypes.bool,
};
