import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  Box,
  Stack,
  Divider,
  Backdrop,
  InputAdornment,
  Dialog,
  Button,
  useTheme,
} from "@mui/material";
import { CountryMenuDropDown } from "../dropdown/CountryMenuDropDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useAuth, useToast } from "../../context";
import { api, urls } from "../../service";

const useStyles = makeStyles((theme) => ({
  modelHeader: {
    padding: "20px 40px",
    borderBottom: "1px solid #DDDDDD",
    textAlign: "center",
  },
  modelBody: {
    padding: "20px 40px",
  },
  modelFooter: {
    padding: "20px 40px",
  },
  center: {
    justifyContent: "center",
  },
}));

export const AddAdminDialog = (props) => {
  const { open, onClose, onSubmit } = props;
  const theme = useTheme();

  const { logout } = useAuth();
  const { showToast } = useToast();

  const [apiCalling, setApiCalling] = useState(true);
  const [rolesList, setRolesList] = useState([]);

  const [countrySelectedMenu, setCountrySelectedMenu] = useState({
    label: "United Arab Emirates",
    code: "AE",
    phone: "971",
  });
  const [countryMenuAnchorEl, setCountryMenuAnchorEl] = useState(null);
  const [validInputs, setValidInputs] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false); // Tracks if any input has been modified
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_code: "+971",
    phone_number: "",
    role_code_name: "",
    errors: {
      name: true,
      email: true,
      phone_number: true,
      role_code_name: true,
    },
  });

  const validateInput = (key, value) => {
    switch (key) {
      case "name":
        return value.trim() === ""; // Example: name should not be empty
      case "email":
        return !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value); // Example: validate email with regex
      case "phone_number":
        return value.length < 6; // Example: phone number should have a minimum length
      case "role_code_name":
        return value.trim() === ""; // Example: role code name should not be empty
      default:
        return false;
    }
  };

  const classes = useStyles();

  const getRolesList = () => {
    setApiCalling(true);
    api
      .get(urls.adminRoles)
      .then((res) => {
        let rolesList = res.data?.data;
        setRolesList(rolesList);
        setApiCalling(false);
      })
      .catch((err) => {
        setApiCalling(false);
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const saveNewUser = () => {
    setHasInteracted(true);
    const hasErrors = Object.values(formData.errors).some((error) => error);

    if (!hasErrors) {
      setApiCalling(true);
      const params = { ...formData };
      delete params.errors;

      api
        .post(urls.createSeller, params)
        .then((res) => {
          showToast(res.data.message, "success");
          setApiCalling(false);
          onSubmit();
        })
        .catch((err) => {
          setApiCalling(false);
          if (err.response.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      // Validate input and set error state
      curr.errors[key] = validateInput(key, value);
      return curr;
    });
  };

  const handleCountryMenuClick = (event) => {
    setCountryMenuAnchorEl(event.currentTarget);
  };

  const handleCountryMenuClose = () => {
    setCountryMenuAnchorEl(null);
  };

  const handleCountryMenuItemClick = (value) => {
    updateFormData("phone_code", `+${value.phone}`);
    setCountrySelectedMenu(value);
    handleCountryMenuClose();
  };

  useEffect(() => {
    if (open) {
      getRolesList();
    }
  }, [open]);

  useEffect(() => {
    // if (hasInteracted) {
    // Check overall validity after the first interaction
    const hasErrors = Object.values(formData.errors).some((error) => error);
    setValidInputs(!hasErrors);
    // }
  }, [formData, hasInteracted]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Stack direction={"column"} sx={{ width: 450, pt: 2, pb: 4 }}>
        <Box className={classes.modelHeader}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 500,
              color: "#000",
              fontStyle: "normal",
            }}
          >
            Add Admin
          </Typography>
        </Box>
        <Box className={classes.modelBody}>
          <Grid container spacing={3} direction="column">
            <Grid item md={12} sx={{ mt: 1 }}>
              <TextField
                className="smallText"
                fullWidth
                id="outlined-required"
                label="Name"
                value={formData.name}
                helperText={
                  formData.errors.name && hasInteracted
                    ? "Please enter the name"
                    : null
                }
                onChange={(e) => updateFormData("name", e.target.value)}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                className="smallText"
                fullWidth
                id="outlined-required"
                label="Email"
                value={formData.email}
                helperText={
                  formData.errors.email && hasInteracted
                    ? "Please enter a valid email"
                    : null
                }
                onChange={(e) => updateFormData("email", e.target.value)}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                required
                fullWidth
                label="Whatsapp Number"
                type="number"
                value={formData.phone_number}
                error={hasInteracted && formData.errors.phone_number}
                helperText={
                  formData.errors.phone_number &&
                  hasInteracted &&
                  formData.phone_number.trim() === ""
                    ? "Please enter the Whatsapp number"
                    : formData.errors.phone_number &&
                      hasInteracted &&
                      (formData.phone_number.length < 7 ||
                        formData.phone_number.length > 15)
                    ? "Enter a valid phone number"
                    : null
                }
                onChange={(e) => updateFormData("phone_number", e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Stack
                        direction={"row"}
                        onClick={handleCountryMenuClick}
                        sx={{ cursor: "pointer" }}
                      >
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: theme.palette.primary.main,
                          }}
                        >
                          {countrySelectedMenu.phone === undefined
                            ? "+971"
                            : `+${countrySelectedMenu.phone}`}
                        </Typography>
                        <ArrowDropDownIcon />
                        <Divider orientation="vertical" flexItem />
                      </Stack>
                      <CountryMenuDropDown
                        countryMenuAnchorEl={countryMenuAnchorEl}
                        handleCountryMenuClose={handleCountryMenuClose}
                        handleCountryMenuItemClick={handleCountryMenuItemClick}
                      />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  sx: {
                    fontSize: 16,
                    fontWeight: 500,
                    color: theme.palette.primary.main,
                  },
                }}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                className="smallText"
                fullWidth
                id="outlined-select-currency"
                select
                label="User Type"
                value={formData.role_code_name}
                helperText={
                  formData.errors.role_code_name && hasInteracted
                    ? "Please select the user role"
                    : null
                }
                onChange={(e) =>
                  updateFormData("role_code_name", e.target.value)
                }
              >
                {rolesList.map((role, index) => {
                  return (
                    <MenuItem key={index} value={role.role_code_name}>
                      {role.role_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
        </Box>
        <Stack direction="row" spacing={2} sx={{ pl: 5, pr: 5 }}>
          <Button
            variant="contained"
            disableElevation
            color="lightTheme"
            onClick={onClose}
            sx={{
              width: "100%",
              height: 45,
              fontWeight: 500,
              fontSize: 16,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="theme"
            sx={{
              width: "100%",
              height: 45,
              fontWeight: 500,
              fontSize: 16,
              textTransform: "none",
            }}
            disabled={apiCalling || !validInputs}
            onClick={saveNewUser}
          >
            Add Admin
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
