import {
  Paper,
  Grid,
  useTheme,
  Divider,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { MultiColumnData } from "../../components/data/MultiColumnData";
import {
  Activity,
  ChartSquare,
  EmptyWallet,
  Profile2User,
} from "iconsax-react";
import { MultiLineChart } from "../../components/charts/MultiLineChart";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { currencyFormatter } from "../../utilities/extensions";

export const DashboardScreen = () => {
  const theme = useTheme();
  const [timelineTab, setTimelineTab] = useState(0);
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [dashboardTiles, setDashboardTiles] = useState(null);
  const [dashboardChart, setDashboardChart] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(false);

  const handleTimelineTabChange = (event, newTimelineTab) => {
    setTimelineTab(newTimelineTab);
  };

  const getDashboardTiles = () => {
    api
      .get(urls.dashboard)
      .then((res) => {
        setDashboardTiles(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getDashbaordChart = () => {
    setIsChartLoading(true);
    let params = {};
    if (timelineTab === 0) {
      params.period = "weekly";
    }
    if (timelineTab === 1) {
      params.period = "monthly";
    }
    if (timelineTab === 2) {
      params.period = "yearly";
    }
    api
      .get(urls.dashboardChart, { params: params })
      .then((res) => {
        setDashboardChart(res.data.data);
        setIsChartLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
        setIsChartLoading(false);
      });
  };

  useEffect(() => {
    getDashboardTiles();
  }, []);

  useEffect(() => {
    getDashbaordChart();
  }, [timelineTab]);

  return (
    <Fragment>
      <Grid container direction={"column"} xs>
        <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
          Dashboard
        </Typography>
        {isLoading && !dashboardTiles && dashboardChart.length === 0 && (
          <LoaderCard />
        )}
        {dashboardTiles && (
          <Fragment>
            <Grid
              container
              direction={"row"}
              xs
              spacing={2}
              sx={{ mt: "0.1px" }}
            >
              <Grid item xs={6}>
                <MultiColumnData
                  bgColor={theme.palette.vlightTheme.main}
                  headerColor={theme.palette.theme.main}
                  headerIcon={
                    <Activity
                      variant="Outline"
                      size={20}
                      color={theme.palette.theme.main}
                    />
                  }
                  headerText={"Today"}
                  col1Header={dashboardTiles.today_total_quantity}
                  col1SubHeader={"Products Sold Today"}
                  col2Header={currencyFormatter(
                    dashboardTiles.today_total_amount,
                    dashboardTiles.currency
                  )}
                  col2SubHeader={"Today Sales"}
                />
              </Grid>
              <Grid item xs={6}>
                <MultiColumnData
                  bgColor={theme.palette.altThemeOneLight.main}
                  headerColor={theme.palette.altThemeOne.main}
                  headerIcon={
                    <ChartSquare
                      variant="Outline"
                      size={20}
                      color={theme.palette.altThemeOne.main}
                    />
                  }
                  headerText={"Total"}
                  col1Header={dashboardTiles.total_sold_quantity}
                  col1SubHeader={"Total Products Sold"}
                  col2Header={currencyFormatter(
                    dashboardTiles.total_earnings,
                    dashboardTiles.currency
                  )}
                  col2SubHeader={"Total Sales"}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction={"row"}
              xs
              spacing={2}
              sx={{ mt: "0.1px" }}
            >
              <Grid item xs={6}>
                <MultiColumnData
                  bgColor={theme.palette.altThemeTwoLight.main}
                  headerColor={theme.palette.altThemeTwo.main}
                  headerIcon={
                    <Profile2User
                      variant="Outline"
                      size={20}
                      color={theme.palette.altThemeTwo.main}
                    />
                  }
                  headerText={"Users"}
                  col1Header={`${dashboardTiles.buyer_count.active_today}/${dashboardTiles.buyer_count.total_buyer}`}
                  col1SubHeader={"Active Buyers Today"}
                  col2Header={`${dashboardTiles.seller_count.active_today}/${dashboardTiles.seller_count.total_seller}`}
                  col2SubHeader={"Sellers Active Today"}
                />
              </Grid>
              <Grid item xs={6}>
                <MultiColumnData
                  bgColor={theme.palette.altThemeThreeLight.main}
                  headerColor={theme.palette.altThemeThree.main}
                  headerIcon={
                    <EmptyWallet
                      variant="Outline"
                      size={20}
                      color={theme.palette.altThemeThree.main}
                    />
                  }
                  headerText={"Balance"}
                  col1Header={currencyFormatter(
                    dashboardTiles.closing_balance,
                    dashboardTiles.currency
                  )}
                  col1SubHeader={"Total"}
                  // col2Header={currencyFormatter(
                  //   dashboardTiles.transaction.transaction_amount,
                  //   dashboardTiles.currency
                  // )}
                  // col2SubHeader={"Total"}
                  hideCol2={true}
                />
              </Grid>
            </Grid>
          </Fragment>
        )}
        {dashboardChart.length > 0 && (
          <Paper elevation={2} sx={{ mt: 2, mb: 2, mr: 2, borderRadius: 2 }}>
            <Grid container direction={"column"} xs>
              <Grid container direction={"row"}>
                <Tabs
                  value={timelineTab}
                  onChange={handleTimelineTabChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: theme.palette.theme.main, // Color for the indicator bar
                    },
                  }}
                >
                  <Tab
                    label="Last 7 days"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 14,
                      fontWeight: 500,
                      textTransform: "none",
                      "&.Mui-selected": {
                        color: theme.palette.theme.main, // Color for the selected text
                      },
                    }}
                  />
                  <Tab
                    label="Last 30 days"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 14,
                      fontWeight: 500,
                      textTransform: "none",
                      "&.Mui-selected": {
                        color: theme.palette.theme.main, // Color for the selected text
                      },
                    }}
                  />
                  <Tab
                    label="Last 12 months"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 14,
                      fontWeight: 500,
                      textTransform: "none",
                      "&.Mui-selected": {
                        color: theme.palette.theme.main, // Color for the selected text
                      },
                    }}
                  />
                </Tabs>
              </Grid>
              <Divider />
              {isChartLoading ? (
                <LoaderCard />
              ) : (
                <MultiLineChart type={timelineTab} data={dashboardChart} />
              )}
            </Grid>
          </Paper>
        )}
      </Grid>
    </Fragment>
  );
};
