import { Grid, Stack, useTheme, Button } from "@mui/material";
import { AboutCompany } from "../../components/data/AboutCompany";
import { Documents } from "../../components/data/Documents";
import { BuyerSettings } from "../../components/data/BuyerSettings";
import { BuyerCompany } from "../../components/data/BuyerCompany";
import { BuyerCompanyTimeStamp } from "../../components/data/BuyerCompanyTimeStamp";
import { useState, useEffect, Fragment } from "react";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import { useParams } from "react-router-dom";
import { LoaderCard } from "../../components/loaders/LoaderCard";
import { EmptyCard } from "../../components/emptyCard/EmptyCard";

export const CompanyDetailsBuyerScreen = (props) => {
  const { isDeleted } = props;
  const theme = useTheme();
  const { id } = useParams();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();
  const [companyDetails, setCompanyDetails] = useState(null);
  const [tradeCategories, setTradeCategories] = useState([]);
  const [accessChanged, setAccessChanged] = useState(null);
  const [settings, setSettings] = useState(null);

  const getCompanyDetails = () => {
    api
      .get(`${urls.registrationRequestDetail}${id}`)
      .then((res) => {
        setCompanyDetails(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getTradeCategories = () => {
    api
      .get(urls.tradeCategoriesList)
      .then((res) => {
        setTradeCategories(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getSettings = () => {
    api
      .get(urls.settingsList)
      .then((res) => {
        setSettings(res.data.data.settings);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getCompanyDetails();
    getTradeCategories();
    getSettings();
  }, []);

  const handleSubmitClick = () => {
    let params = {
      purchase_limit: companyDetails.purchase_limit,
      deposit_amount: companyDetails.deposit_amount,
      payment_terms: companyDetails.payment_terms,
      trade_category: companyDetails.trade_category.uuid,
      registration_status: companyDetails.registration_status,
      is_guest: companyDetails.is_guest,
    };

    api
      .post(urls.approveRejectUser + id, params)
      .then((res) => {
        showToast(res.data.message, "success");
        getCompanyDetails();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const isInvalid = () => {
    return (
      !companyDetails?.trade_category?.id ||
      !companyDetails?.payment_terms ||
      !companyDetails?.deposit_amount ||
      !companyDetails?.purchase_limit
    );
  };

  const handleAccessChange = (status) => {
    console.log(status);
    if (status === "full_access") {
      setCompanyDetails({
        ...companyDetails,
        registration_status: true,
        is_guest: false,
      });
    } else if (status === "view_only") {
      setCompanyDetails({
        ...companyDetails,
        registration_status: true,
        is_guest: true,
      });
    } else if (status === "deactivate") {
      setCompanyDetails({
        ...companyDetails,
        registration_status: false,
      });
    } else if (status === "active") {
      setCompanyDetails({
        ...companyDetails,
        registration_status: true,
      });
    }
    // if (companyDetails.user_type === "Seller") {
    //   handleStatusUpdate(status === "active" ? true : false);
    // }
    if (!accessChanged) {
      setAccessChanged(status);
    }
  };

  useEffect(() => {
    if (accessChanged) {
      setTimeout(() => {
        if (!isInvalid()) {
          handleSubmitClick();
        } else if (isInvalid()) {
          showToast("Please fill all required fields in settings", "error");
        }
        setAccessChanged(null);
      }, 500);
    }
  }, [accessChanged]);

  return (
    <Fragment>
      {isLoading && !companyDetails && <LoaderCard />}
      {!isLoading && !companyDetails && <EmptyCard text="No Details" />}
      {companyDetails && (
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Stack direction={"column"} sx={{ gap: 2 }}>
              <AboutCompany data={companyDetails} />
              <Documents
                data={companyDetails?.user_certificates ?? []}
                userID={companyDetails?.id}
                onChange={() => getCompanyDetails()}
                userType="Buyer"
                isDeleted={isDeleted}
              />
              <BuyerSettings
                categories={tradeCategories}
                category={companyDetails?.trade_category?.uuid}
                onCategoryChange={(id) =>
                  setCompanyDetails({
                    ...companyDetails,
                    trade_category: {
                      ...companyDetails.trade_category,
                      uuid: id,
                      id: id,
                    },
                  })
                }
                terms={companyDetails?.payment_terms}
                onTermsChange={(terms) =>
                  setCompanyDetails({
                    ...companyDetails,
                    payment_terms: terms,
                  })
                }
                deposit={companyDetails?.deposit_amount}
                onDepositChange={(deposit) =>
                  setCompanyDetails({
                    ...companyDetails,
                    deposit_amount: deposit,
                    purchase_limit: deposit * 10,
                  })
                }
                purchase={companyDetails?.purchase_limit}
                onPurchaseChange={(purchase) =>
                  setCompanyDetails({
                    ...companyDetails,
                    purchase_limit: purchase,
                  })
                }
                currency={settings?.currency}
                isDeleted={isDeleted}
              />
              {!isDeleted && (
                <Button
                  variant="contained"
                  disableElevation
                  color="theme"
                  onClick={handleSubmitClick}
                  style={{ textTransform: "none" }}
                  sx={{
                    width: 150,
                    height: 45,
                  }}
                  disabled={isInvalid()}
                >
                  Submit
                </Button>
              )}
            </Stack>
          </Grid>
          <Grid item xs>
            <Stack direction={"column"} sx={{ gap: 2 }}>
              <BuyerCompany
                details={companyDetails}
                onStatusChange={handleAccessChange}
                isDeleted={isDeleted}
              />
              <BuyerCompanyTimeStamp
                limitAmount={companyDetails?.current_purchase_limit ?? ""}
                currency={settings?.currency ?? ""}
                date={companyDetails?.joined_on ?? ""}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
