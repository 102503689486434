import { useTheme } from "@emotion/react";
import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { SettingsTab } from "../../components/tabs/SettingsTab";
import { UserSettingsTable } from "../../components/table/UserSettingsTable";
import { ProductSettingsMenu } from "../../components/menu/ProductSettingsMenu";
import { SettingsMenu } from "../../components/menu/SettingsMenu";

export const SettingsScreen = () => {
  const theme = useTheme();
  const [settingsTab, setSettingsTab] = useState(0);

  const handleSettingsTabChange = (event, newSettingsTab) => {
    setSettingsTab(newSettingsTab);
  };

  return (
    <Fragment>
      <Grid container direction={"column"} xs sx={{ pr: 2 }}>
        <Grid item>
          <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
            Settings
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <SettingsTab
            settingsTab={settingsTab}
            handleSettingsTabChange={handleSettingsTabChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, mr: 2 }}>
        {settingsTab === -1 ? (
          <UserSettingsTable />
        ) : settingsTab === 0 ? (
          <ProductSettingsMenu />
        ) : (
          <SettingsMenu />
        )}
      </Box>
    </Fragment>
  );
};
