import { Fragment, useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { sampleSellersItems } from "../../utilities";
import { useTheme } from "@emotion/react";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";
import PropTypes from "prop-types";
import { LoaderCard } from "../loaders/LoaderCard";
import { EmptyCard } from "../emptyCard/EmptyCard";
import { currencyFormatter } from "../../utilities/extensions";
import { useUsersStore } from "../../store/store";

const useStyles = makeStyles(() => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  table: {
    minWidth: 650,
    borderRadius: "10px",
    borderCollapse: "unset !important",

    justifyContent: "left",
  },
  border: {
    borderBottom: "0px !important",
  },
}));

export const AdminsTable = (props) => {
  const { onCountChange, searchInput } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const { api, urls, isLoading } = useAPI();
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sellers,
    setSellers,
    totalCount,
    setTotalCount,
    currency,
    setCurrency,
    isFirstLoad,
    setIsFirstLoad,
    shouldReload,
    setShouldReload,
  } = useUsersStore((state) => state);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShouldReload(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setShouldReload(true);
  };

  function createData(id, company, totalorders, totalsales, balance) {
    return { id, company, totalorders, totalsales, balance };
  }

  const headerStyle = {
    fontWeight: 600,
    fontSize: "16px",
    color: theme.palette.black.main,
  };

  const itemStyle = {
    fontWeight: 500,
    fontSize: "16px",
    color: theme.palette.tablecolor.main,
  };

  const getSellers = () => {
    let params = {
      page: page + 1,
      limit: rowsPerPage,
      search_key: searchInput,
    };
    api
      .get(urls.adminsList, { params: params })
      .then((res) => {
        setSellers(res.data.data?.sellers ?? []);
        setTotalCount(res.data.data?.pagination?.total_sellers ?? 0);
        onCountChange({
          buyers: res.data.data?.pagination?.total_buyers ?? 0,
          sellers: res.data.data?.pagination?.total_sellers ?? 0,
          admins: res.data.data?.pagination?.total_admins ?? 0,
        });
        setCurrency(res.data.data?.currency ?? "");
        setIsFirstLoad(false);
        setShouldReload(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        }
        showToast(err.response?.data?.message, "error");
      });
  };

  useEffect(() => {
    if (isFirstLoad) {
      getSellers();
    }
  }, []);

  useEffect(() => {
    if (shouldReload) {
      getSellers();
    }
  }, [shouldReload]);

  const getSLNo = (i) => {
    return i + 1 + page * rowsPerPage;
  };

  return (
    <Fragment>
      {isLoading && sellers.length === 0 && <LoaderCard />}
      {!isLoading && sellers.length === 0 && <EmptyCard />}
      {sellers.length > 0 && (
        <TableContainer component={Box}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell sx={headerStyle}>No.</TableCell>
                <TableCell sx={headerStyle}>Company</TableCell>
                <TableCell sx={headerStyle}>Seller ID</TableCell>
                <TableCell sx={headerStyle}>Total Orders</TableCell>
                <TableCell sx={headerStyle}>Total Sales</TableCell>
                <TableCell sx={headerStyle} align="right">
                  Closing balance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sellers.map((item, i) => (
                <TableRow key={i} hover>
                  <TableCell sx={itemStyle}>{getSLNo(i)}</TableCell>
                  <TableCell sx={itemStyle}>{item.company_name}</TableCell>
                  <TableCell sx={itemStyle}>{item.seller_id}</TableCell>
                  <TableCell sx={itemStyle} component="th" scope="row">
                    {item.total_orders_count}
                  </TableCell>
                  <TableCell sx={itemStyle}>
                    {currencyFormatter(item.total_price_sum, currency)}
                  </TableCell>
                  <TableCell sx={itemStyle} align="right">
                    {currencyFormatter(item.closing_balance, currency)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {sellers.length > 0 && (
        <TablePagination
          component="div"
          align="right"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ mt: 1, mb: 1 }}
        />
      )}
    </Fragment>
  );
};

AdminsTable.propTypes = {
  onCountChange: PropTypes.func,
  searchInput: PropTypes.string,
};
