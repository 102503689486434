import { useContext, useState, createContext, useEffect } from "react";
import { useLocalStorage } from "../hooks";

const AuthContext = createContext();

const AuthProvider = (props) => {
  const { getItem, removeItem } = useLocalStorage();

  const token = getItem("access_token");
  const user = getItem("user_data");

  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(token));
  const [userData, setUserData] = useState(JSON.parse(user));

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
    removeItem("access_token");
    removeItem("user_data");
  };

  useEffect(() => {
    setUserData(JSON.parse(user));
  }, [user]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, userData }}>
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
