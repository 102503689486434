import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Crown1 } from "iconsax-react";
import { RequestDetailRadio } from "../radio/RequestDetailRadio";
import PropTypes from "prop-types";

export const SellerAccessCard = (props) => {
  const theme = useTheme();
  const { status, onStatusChange } = props;
  return (
    <Box
      sx={{
        bgcolor: theme.palette.buyerAccessBg.main,
        p: 2,
        borderRadius: 2,
      }}
    >
      <Stack direction={"column"}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontSize: 16,
            fontWeight: 600,
            pb: 1,
          }}
        >
          Seller Access
        </Typography>
        <RequestDetailRadio
          userType={"Seller"}
          status={status}
          onStatusChange={onStatusChange}
        />
        {/* <Divider sx={{ mb: 2, mt: 1, mr: 1, ml: 1 }} />
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
              pb: 1,
            }}
          >
            Referral Code
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 16,
              fontWeight: 500,
              pb: 1,
            }}
          >
            $53673
          </Typography>
        </Stack> */}
      </Stack>
    </Box>
  );
};

SellerAccessCard.propTypes = {
  status: PropTypes.string,
  onStatusChange: PropTypes.func,
};
