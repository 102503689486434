import { create } from "zustand";

export const usePurchaseStore = create((set) => {
  return {
    isFirstLoad: true,
    setIsFirstLoad: (isFirstLoad) => set({ isFirstLoad }),
    shouldReload: false,
    setShouldReload: (shouldReload) => set({ shouldReload }),
    purchase: [],
    setPurchase: (purchase) => set({ purchase }),
    page: 0,
    setPage: (page) => set({ page }),
    rowsPerPage: 10,
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    totalCount: 0,
    setTotalCount: (totalCount) => set({ totalCount }),
    status: "",
    setStatus: (status) => set({ status }),
    date: "",
    setDate: (date) => set({ date }),
    search: "",
    setSearch: (search) => set({ search }),
    resetStore: () =>
      set({
        isFirstLoad: true,
        shouldReload: false,
        purchase: [],
        page: 0,
        rowsPerPage: 10,
        totalCount: 0,
        status: "",
        date: "",
        search: "",
      }),
  };
});

export const useSellStore = create((set) => {
  return {
    isFirstLoad: true,
    setIsFirstLoad: (isFirstLoad) => set({ isFirstLoad }),
    shouldReload: false,
    setShouldReload: (shouldReload) => set({ shouldReload }),
    sellList: [],
    setSellList: (sellList) => set({ sellList }),
    page: 0,
    setPage: (page) => set({ page }),
    rowsPerPage: 10,
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    totalCount: 0,
    setTotalCount: (totalCount) => set({ totalCount }),
    status: "",
    setStatus: (status) => set({ status }),
    date: "",
    setDate: (date) => set({ date }),
    search: "",
    setSearch: (search) => set({ search }),
    resetStore: () =>
      set({
        isFirstLoad: true,
        shouldReload: false,
        sellList: [],
        page: 0,
        rowsPerPage: 10,
        totalCount: 0,
        status: "",
        date: "",
        search: "",
      }),
  };
});

export const useRequestsStore = create((set) => {
  return {
    isFirstLoad: true,
    setIsFirstLoad: (isFirstLoad) => set({ isFirstLoad }),
    shouldReload: false,
    setShouldReload: (shouldReload) => set({ shouldReload }),
    requests: [],
    setRequests: (requests) => set({ requests }),
    page: 0,
    setPage: (page) => set({ page }),
    rowsPerPage: 10,
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    totalCount: 0,
    setTotalCount: (totalCount) => set({ totalCount }),
    status: "",
    setStatus: (status) => set({ status }),
    search: "",
    setSearch: (search) => set({ search }),
    pagination: {
      currentPage: "1",
      limit: "10",
      total_count: 0,
    },
    setPagination: (pagination) => set({ pagination }),
    resetStore: () =>
      set({
        isFirstLoad: true,
        shouldReload: false,
        requests: [],
        page: 0,
        rowsPerPage: 10,
        totalCount: 0,
        status: "",
        search: "",
      }),
  };
});

export const useUsersStore = create((set) => {
  return {
    isFirstLoad: true,
    setIsFirstLoad: (isFirstLoad) => set({ isFirstLoad }),
    shouldReload: false,
    setShouldReload: (shouldReload) => set({ shouldReload }),
    tradeCategories: [],
    setTradeCategories: (tradeCategories) => set({ tradeCategories }),
    buyers: [],
    setBuyers: (buyers) => set({ buyers }),
    sellers: [],
    setSellers: (sellers) => set({ sellers }),
    page: 0,
    setPage: (page) => set({ page }),
    rowsPerPage: 10,
    setRowsPerPage: (rowsPerPage) => set({ rowsPerPage }),
    totalCount: 0,
    setTotalCount: (totalCount) => set({ totalCount }),
    catID: "",
    setCatID: (catID) => set({ catID }),
    searchInput: "",
    setSearchInput: (searchInput) => set({ searchInput }),
    tabValue: 0,
    setTabValue: (tabValue) => set({ tabValue }),
    value: 0,
    setValue: (value) => set({ value }),
    listCount: {
      buyers: 0,
      sellers: 0,
    },
    setListCount: (listCount) => set({ listCount }),
    currency: "",
    setCurrency: (currency) => set({ currency }),
    resetStore: () =>
      set({
        isFirstLoad: true,
        shouldReload: false,
        tradeCategories: [],
        buyers: [],
        sellers: [],
        page: 0,
        rowsPerPage: 10,
        totalCount: 0,
        catID: "",
        searchInput: "",
        tabValue: 0,
        value: 0,
        listCount: {
          buyers: 0,
          sellers: 0,
          admins: 0,
        },
        currency: "",
      }),
    resetTab: () =>
      set({
        isFirstLoad: true,
        shouldReload: false,
        buyers: [],
        sellers: [],
        page: 0,
        rowsPerPage: 10,
        totalCount: 0,
        catID: "",
        searchInput: "",
      }),
  };
});
