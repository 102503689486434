import "./App.css";
import { Fragment, useState } from "react";
import { ThemeProvider, Box } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { lightTheme, darkTheme } from "./theme";
import { MainRoutes } from "./routes/MainRoutes";
import { AuthProvider, ToastProvider } from "./context";
import { Authentication } from "./routes/Authentication";
import pkgJSON from "../package.json";

function App() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <Fragment>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <Box
          sx={{
            minHeight: "100vh",
          }}
        >
          <AuthProvider>
            <ToastProvider>
              <Router>
                <Authentication />
                <MainRoutes />
              </Router>
            </ToastProvider>
          </AuthProvider>
        </Box>
      </ThemeProvider>
      {console.info("🚀 App version: " + pkgJSON.version + ", 11 Sep 2024")}
    </Fragment>
  );
}

export default App;
