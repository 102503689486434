import React, { useState, useCallback, Fragment } from "react";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { SubCategoryCard } from "../cards/SubCategoryCard";
import { Add } from "iconsax-react";
import { AddSubCategory } from "../alert/AddSubCategory";
import PropTypes from "prop-types";
import CategoryEditDeleteMenu from "./CategoryEditDeleteMenu";
import { useModal } from "../../hooks";
import { ReminderDialog } from "../dialogs/ReminderDialog";
import { useAuth, useToast } from "../../context";
import { useAPI } from "../../hooks/useAPI";

export const SubCategoryMenu = React.memo((props) => {
  const {
    data,
    onSubCategoryClick,
    title,
    selectedID,
    onSubcategoryChange,
    catID,
    isParentHidden,
  } = props;
  const theme = useTheme();
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [editSubCategory, setEditSubCategory] = useState(null);
  const [delSubCategory, setDelSubCategory] = useState(null);
  const [isOpenDeleteDialog, openDeleteDialog, closeDeleteDialog] = useModal();
  const [isOpenReactivateDialog, openReactivateDialog, closeReactivateDialog] =
    useModal();

  const { logout } = useAuth();
  const { showToast } = useToast();
  const { api, urls, isLoading } = useAPI();

  const handleOpenSub = useCallback(() => {
    setOpenSubDialog(true);
  }, []);

  const handleCloseSub = useCallback(() => {
    setOpenSubDialog(false);
    setEditSubCategory(null);
  }, []);

  const handleEditClick = useCallback(
    (item) => {
      setEditSubCategory(item);
      handleOpenSub();
    },
    [handleOpenSub]
  );

  const handleDeleteCategory = () => {
    api
      .delete(urls.removeCategory + delSubCategory.id)
      .then((res) => {
        showToast(res.data.message, "success");
        onSubcategoryChange(true); // true to clear prodCategory
        closeDeleteDialog();
        setDelSubCategory(null);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleUnhideCategory = () => {
    api
      .put(urls.unhideCategory + delSubCategory.id)
      .then((res) => {
        showToast(res.data.message, "success");
        onSubcategoryChange(true); // true to clear prodCategory
        closeReactivateDialog();
        setDelSubCategory(null);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const hasChildItem = (item) => {
    return item.sub_categories?.length > 0 || item.has_products;
  };
  const isHiddenItem = (item) => {
    // return !(!item.status || isParentHidden);
    return !item.status;
  };

  return (
    <Stack
      direction="column"
      sx={{
        bgcolor: theme.palette.menuHeader.main,
        borderRadius: 2,
        width: 300,
        height: "88vh",
      }}
    >
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontSize: 16,
          fontWeight: 600,
          p: "12px",
        }}
      >
        {title}
      </Typography>
      <Divider />
      <List
        sx={{
          height: "100%",
          mt: "-4px",
          flexWrap: "inherit",
          overflowX: "scroll",
        }}
      >
        {data.map((item) => (
          <Fragment>
            {!isParentHidden && (
              <ListItem
                key={item.id}
                disablePadding
                secondaryAction={
                  <CategoryEditDeleteMenu
                    onEditClick={() => handleEditClick(item)}
                    onDeleteClick={(isDeactivated) => {
                      setDelSubCategory(item);
                      if (isDeactivated === true) {
                        //Already deactivated, so reactivate
                        openReactivateDialog();
                      } else {
                        openDeleteDialog();
                      }
                    }}
                    hasChildItem={hasChildItem(item)}
                    isHiddenItem={isHiddenItem(item)}
                  />
                }
              >
                <ListItemButton
                  sx={{ p: 0 }}
                  onClick={() => onSubCategoryClick(item)}
                  selected={item.id === selectedID}
                >
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <SubCategoryCard
                      node={item}
                      isHidden={isHiddenItem(item)}
                    />
                    <Divider sx={{ mr: "-50px" }} />
                  </Stack>
                </ListItemButton>
              </ListItem>
            )}
            {isParentHidden && (
              <ListItemButton
                sx={{ p: 0 }}
                onClick={() => onSubCategoryClick(item)}
                selected={item.id === selectedID}
              >
                <Stack direction="column" sx={{ width: "100%" }}>
                  <SubCategoryCard node={item} isHidden={isHiddenItem(item)} />
                  <Divider sx={{ mr: "-50px" }} />
                </Stack>
              </ListItemButton>
            )}
          </Fragment>
        ))}
      </List>
      <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
        {!isParentHidden && (
          <Button
            variant="contained"
            disableElevation
            color="theme"
            onClick={handleOpenSub}
            startIcon={<Add />}
            style={{ textTransform: "none" }}
            sx={{ width: "100%", height: 45 }}
          >
            Add Subcategory
          </Button>
        )}
        {openSubDialog && (
          <AddSubCategory
            open={openSubDialog}
            onClose={handleCloseSub}
            catID={catID}
            onSubmit={onSubcategoryChange}
            isEdit={Boolean(editSubCategory)}
            data={editSubCategory}
          />
        )}
        {isOpenDeleteDialog && (
          <ReminderDialog
            open={isOpenDeleteDialog}
            onClose={() => {
              closeDeleteDialog();
              setDelSubCategory(null);
            }}
            onConfirm={handleDeleteCategory}
            header={hasChildItem(delSubCategory) ? "Hide" : "Remove"}
            reminder={
              hasChildItem(delSubCategory)
                ? "Are you sure to hide this category?"
                : "Are you sure to remove this category?"
            }
            confirm={hasChildItem(delSubCategory) ? "Hide" : "Remove"}
          />
        )}
        {isOpenReactivateDialog && (
          <ReminderDialog
            open={isOpenReactivateDialog}
            onClose={closeReactivateDialog}
            onConfirm={handleUnhideCategory}
            header={"Unhide"}
            reminder={"Are you sure to unhide this category?"}
            confirm={"Unhide"}
          />
        )}
      </Box>
    </Stack>
  );
});

SubCategoryMenu.propTypes = {
  data: PropTypes.array,
  onSubCategoryClick: PropTypes.func,
  title: PropTypes.string,
  selectedID: PropTypes.string,
  onSubcategoryChange: PropTypes.func,
  catID: PropTypes.string,
  isParentHidden: PropTypes.bool,
};

SubCategoryMenu.defaultProps = {
  isParentHidden: false,
};
